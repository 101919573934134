import React from 'react';
import Layout from '../common/layouts';
import Img from 'gatsby-image';
import { graphql, Link } from 'gatsby';
import Seo from '../common/seo';
import MailchimpReact from "mailchimp-react";
import jsonp from 'jsonp';
import queryString from 'query-string';
//
// const subscribeToNewsLetter = (email) => {
//     const formData = {
//         EMAIL: email // your email string,
//     };
//     jsonp(`https://fusionbrow.us7.list-manage.com/subscribe/post?u=97c9ad92c387c4e57c3054e09&amp;id=b605b5fa9b&${queryString.stringify(formData)}`, { param: 'c' }, (err, data) => {
//         console.log('err:', err);
//         console.log('data:', data);
//     });
// }


export default ({props, data}) => (
  <Layout>
    <Seo
      title={`About`}
      description={data.markdownRemark.frontmatter.title} />
    <div className="relative">
      <Img fluid={data.banner.childImageSharp.fluid}
      />
      <h1
        className="fw1 tc f2 display absolute dn dib-ns white"
        style={{bottom: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>About {data.site.siteMetadata.title}</h1>
    </div>
    <div className="mw9 center flex flex-wrap pv5-l w-100">
      <div className="mw7 w-100 pa2">
        <h1 className="display fw1 db lh-copy">{data.markdownRemark.frontmatter.title}</h1>

          <div className="mw7 w-100 lh-copy serif pa2 flex flex-column justify-center f4">
              We started Fusion Brow Bar with a vision in mind. The vision was to make sure that each one of our clients is satisfied with the services we provide in
              eyebrow threading, waxing, facials, henna, and others. We have
              cosmetologists with 15+ years of experience in these services.
          </div>

          <div className="mw7 w-100 lh-copy serif pa2 flex flex-column justify-center f4">
              We get clients seeking eyebrow threading, waxing and facial services from the entire Rockland County area, including Mahwah, Ramsey, Monsey, Suffern, Nanuet, Nyack, and many more!
          </div>

          <div className="mw7 w-100 lh-copy serif pa2 flex flex-column justify-center f4">
              We love to see our customers being happy with our services. We strongly believe that everyone deserves the best of these services, and with that mindset, we strive to cater to all of our clients' needs.
          </div>
          <div className="mw7 w-100 lh-copy serif pa4 flex flex-column justify-center f4">For the newest looks, updates, and offers, sign up for our newsletter and follow us on social media :)</div>

          <a href='https://fb.com/fusionbrowbar' target='_blank' className="dib bg-dark-gray b near-white hover-bg-mid-gray pv3 ph4 ttu tracked sans-serif no-underline mv2">
              Facebook</a>
          <a rel="noopener noreferrer" href='https://instagram.com/fusionbrowbar' target='_blank' className="dib bg-dark-gray b near-white hover-bg-mid-gray pv3 ph4 ttu tracked sans-serif no-underline mv2">Instagram</a>

          {/*<input*/}
          {/*onSubmit={subscribeToNewsLetter(e.target.value)}*/}
          {/*/>*/}


      </div>

        <div>

            <div className="mw7 w-100 lh-copy serif pa2 flex flex-column justify-center f1">
                 Our Location
            </div>
            <iframe
                className='mw7 w-100'
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12023.335582670215!2d-74.1536084!3d41.1163186!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb7a14519f6cc4189!2sFusion%20Brow%20Bar!5e0!3m2!1sen!2sus!4v1606941845358!5m2!1sen!2sus"
                frameBorder="0"
                allowFullScreen="true"
                aria-hidden="false" tabIndex="0"/>

        </div>

    </div>
  </Layout>
)

export const dataQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: {name: {eq: "about__bio"}}) {
      html
      frontmatter {
        title
      }
    }
    banner: file(relativePath: {eq: "img/eyebrow.jpg"}) {
      childImageSharp {
        fluid(maxHeight: 900, maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
